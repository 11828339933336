//
// typography
//

// spacing fix
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6 {
  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  margin-bottom: 0;
}

a {
  @include transition;
}

code {
  padding: rem(4) rem(8);
  background: $gray-200;
  color: $black;
  font-weight: $font-weight-bold;
}

// eyebrow
%eyebrow {
  display: inline-block;
  font-size: rem(12);
  font-family: $headings-font-family;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.eyebrow {
  @extend %eyebrow;
}

// underline
%underline {
  @include nofocus;
  display: inline-block;
  position: relative;

  &::before {
    @include transition(0.4s, width, 0s);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    border-bottom: $border-width solid currentColor;
  }

  &:hover {
    text-decoration: none;

    &::before {
      width: 100%;
    }
  }
}

.underline {
  @extend %underline;
}

// action link style
%action {
  @include transition;
  display: inline-block;
  position: relative;
  padding-right: 2em;
  cursor: pointer;

  .bi {
    @include transition;
    @include translateY(-50%);
    position: absolute;
    right: 0.5em;
    top: 50%;
    display: block;
  }

  &:hover {
    .bi {
      right: 0;
    }
  }
}

.action {
  @extend %action;
}

// list inline separated
.list-inline-separated {
  .list-inline-item {
    position: relative;
  }

  .list-inline-item:not(:last-child) {
    margin: 0;

    &::after {
      content: "/";
      margin: 0 rem(8);
    }
  }
}

// text shadow
.text-shadow {
  text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
}

hr.or {
  position: relative;
  background-color: $border-color;

  &::before {
    transform: translate(-50%, -50%);
    position: absolute;
    content: "or";
    left: 50%;
    top: 50%;
    background: $white;
    padding: 0 rem(16);
  }
}

// shine
.shine {
  overflow: hidden;
  background: linear-gradient(90deg, rgba($white, 0), $white, rgba($white, 0));
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}